
        let gqlDocs = [{"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LocalUser"},"variableDefinitions":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"},"arguments":[]}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"isConnected"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":104}}];
        let attrs = {"noAnonymousOperations":false,"vue":true,"type":"custom","index":"0","blockType":"graphql"} || {};
        let handler = require("D:\\a\\1\\s\\YellowDuck.FE\\node_modules\\vue-graphql-loader\\lib\\handler");

        if (handler.default) {
          handler = handler.default;
        }
        module.exports = function vueGraphqlLoader(component) {
          handler(component, gqlDocs, attrs);
        }